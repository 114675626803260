import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import Conctatenos from './Conctatenos';
import { aplicarVerificacionCorreo, cambiarPassword, cancelarTiemposResultadosFamilia, registrarReaccion, registrarVisualizaciones, traerClientes, traerReacciones, traerResultadosFamilia, traerTotalVisualizaciones, traerVisualizaciones, verificarCode } from '../controllers/configuracionFirebase';
import { time, timeLocal } from '../controllers/tiempoLimiteInscripcion';
import { arreglarNombresCortos, arreglarTiempoEstandar } from '../controllers/pdf';
import { FcApproval, FcHighPriority } from "react-icons/fc";
import { Dropdown } from 'primereact/dropdown';
import { Tooltip as Mensaje } from "react-tooltip";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { getCookieReaccion, setReaccionCookie } from "../controllers/cookies";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Password } from "primereact/password";
import toast from 'react-hot-toast';
import { IoLogoFacebook, IoLogoWhatsapp, IoMdMail, IoLogoInstagram, IoLogoYoutube, IoIosPaperPlane } from 'react-icons/io'
import Calendario from './Calendario';
import PixelCard from './PixelCard';
import CountUp from './CountUp';
import GradientText from './GradientText';
import Swal from 'sweetalert2';
import Records from './Records';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Torneos = ({ fechaGoogle, recordsMundial, recordsNacional }) => {

  const [ cliente, setCliente ] = useState([]);
  const [ clientesClub, setClientesClub ] = useState([]);
  const [ selectedClub, setSelectedClub ] = useState(null);
  const [ dialogHistorial, setDialogHistorial ] = useState(false);
  const [ dialogCotizacion, setDialogCotizacion ] = useState(false);
  const [ dialogChampions, setDialogChampions ] = useState(false);
  const [ dialogResultados, setDialogResultados ] = useState(false);
  const [ dialogCalendario, setDialogCalendario ] = useState(false);
  const [ historialDeportista, setHistorialDeportista ] = useState();
  const [ resultados, setResultados ] = useState([]);
  const [ reacciones, setReacciones ] = useState();
  const [ totalVisualizaciones, setTotalVisualizaciones ] = useState(0);
  const [ globalFilter, setGlobalFilter ] = useState('');
  const [ cantPuestos, setCantPuestos ] = useState('');
  const [ identidad, setIdentidad ] = useState('');
  const [ nombreClub, setNombreClub ] = useState('');
  const [ nombresClubs, setNombresClubs ] = useState('');
  const [ animateList, setAnimateList ] = useState(false);
  const [ contadorQueryQr, setContadorQueryQr ] = useState(0);
  const [ contadorQueryNewPassword, setContadorNewPassword ] = useState(0);
  const [ nuevaContrasena, setNuevaContrasena ] = useState('');
  const [ dialogNuevaContrasena, setDialogNuevaContrasena ] = useState(false);
  const [ consultarRanking, setConsultarRanking ] = useState(false);

  const resultadosRef = React.useRef(resultados);
  const query = useQuery();
  const navigate = useNavigate();
  const imagenesPortada = [
    {image: require('../img/img-dispositivo.webp')},
    {image: require('../img/img-equipo-pws.webp')},
    {image: require('../img/img-deportista-master.webp')},
    {image: require('../img/img-deportista-master-m.webp')},
  ]
  const imagenes = [
    {titulo: 'INICIO SESIÓN', 
      descripcion: 'Permite a los usuarios acceder a su cuenta de manera segura para gestionar competencias, visualizar resultados y utilizar funcionalidades exclusivas de la plataforma.', image: require('../img/img-inicio-sesion.png')},
    {titulo: 'REGISTRO DE EQUIPOS', 
      descripcion: 'Permite inscribir equipos de manera rápida y sencilla, facilitando su participación en competencias y la gestión de información relacionada.', image: require('../img/img-registrarse.png')},
    {titulo: 'TORNEOS', 
      descripcion: 'Muestra un catálogo actualizado de competencias disponibles, con detalles clave como fechas, categorías y estado de inscripción.', image: require('../img/img-torneos.png')},
    {titulo: 'INSCRIPCIONES', 
      descripcion: 'Gestiona de manera completa la inscripción de deportistas, permitiendo agregar, modificar o eliminar participantes, verificar edades, inscribir relevos, registrar directivos y generar reportes detallados de inscritos.', image: require('../img/img-inscripciones.png')},
    {titulo: 'CONTROL EQUIPOS', 
      descripcion: 'Permite registrar equipos de forma personalizada, incluyendo deportistas y directivos. Facilita la inscripción de deportistas en carriles específicos, la eliminación de inscripciones, la generación de reportes de equipos inscritos y el envío de resultados o programación al correo de los equipos inscritos.', image: require('../img/img-control-equipos.png')},
    {titulo: 'PROGRAMACIÓN', 
      descripcion: 'Permite visualizar y resaltar la programación de deportistas con cuatro colores disponibles, además de descargar la programación en inglés o español.', image: require('../img/img-programacion.png')},
    {titulo: 'RESULTADOS EN TIEMPO REAL', 
      descripcion: 'Muestra el desempeño de los deportistas al instante, con actualizaciones automáticas para un seguimiento preciso y dinámico de las competencias.', image: require('../img/img-resultados.png')},
    {titulo: 'RESULTADOS EN TIEMPO REAL FAMILIAS', 
      descripcion: 'Accede fácilmente a los resultados sin necesidad de ingresar a la plataforma. Ideal para familiares que desean dar seguimiento en tiempo real al desempeño de los atletas durante el evento.', image: require('../img/img-resultados-familias.png')},
  ]

  function arreglarFecha(fecha){
    const auxFecha = new Date(fecha);
    auxFecha.setDate(auxFecha.getDate() + 1);
    const fechaArreglada = auxFecha.toLocaleDateString('es-ES',{ 
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long'})
    return fechaArreglada;
  }

  function traerImagenTorneo(club){
    const objClub = {
      url: require("../img/img_logo_swimmingT.png"),
      colorTitulo: 'rgb(255,255,255)',
      colorFecha: 'rgb(0,0,0)'
    }
    switch (club) {
      case 'piranitas':
        objClub.url = require("../img/img-fondo-piranitas.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #115F88)'
        objClub.colorFecha = '#115F88'
        break;
      case 'polares':
        objClub.url = require("../img/img-fondo-polares.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #08D2D9)'
        objClub.colorFecha = '#08D2D9'
        break;
      case 'orcas-valle': 
        objClub.url = require("../img/img-fondo-orcasvalle.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #10699A)'
        objClub.colorFecha = '#10699A'
        break;
      case 'linavalle': 
        objClub.url = require("../img/img-fondo-linavalle.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #C00000)'
        objClub.colorFecha = '#C00000'
        break;
      case 'karina-vivas': 
        objClub.url = require("../img/img-fondo-karinavivas.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #086E98)'
        objClub.colorFecha = '#086E98'
        break;
      case 'fundacion-arbe': 
        objClub.url = require("../img/img-fondo-arbe.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #093A8F)'
        objClub.colorFecha = '#093A8F'
        break;
      case 'fecna': 
        objClub.url = require("../img/img-fondo-fecna.webp")
        break;
      case 'atlas-narino': 
        objClub.url = require("../img/img-fondo-atlas.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #0B6EB2)'
        objClub.colorFecha = '#0B6EB2'
        break;
      case 'leones-acuaticos': 
        objClub.url = require("../img/img-fondo-leonesacuaticos.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #B0AFAD)'
        objClub.colorFecha = '#B0AFAD'
        break;
      case 'tiburones-blancos': 
        objClub.url = require("../img/img-fondo-tiburonesblancos.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #1F586B)'
        objClub.colorFecha = '#1F586B'
        break;
      case 'kairos': 
        objClub.url = require("../img/img-fondo-kairos.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #087EC6)'
        objClub.colorFecha = '#087EC6'
        break;
      case 'makos-palmira': 
        objClub.url = require("../img/img-fondo-makospalmira.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #0B6EB2)'
        objClub.colorFecha = '#0B6EB2'
        break;
      case 'comfacauca': 
        objClub.url = require("../img/img-fondo-comfacauca.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #2F3C73)'
        objClub.colorFecha = '#2F3C73'
        break;
      case 'dragones-marinos': 
        objClub.url = require("../img/img-fondo-dragonesmarinos.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #054DA6)'
        objClub.colorFecha = '#054DA6'
        break;
      case 'gold-swimmers': 
        objClub.url = require("../img/img-fondo-goldswimmers.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #E6BA70)'
        objClub.colorFecha = '#E6BA70'
        break;
      case 'estrellas-del-valle': 
        objClub.url = require("../img/img-fondo-estrellas-del-valle.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #8BD864)'
        objClub.colorFecha = '#8BD864'
        break;
      case 'pacific': 
        objClub.url = require("../img/img-fondo-pacific.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #00A2CE)'
        objClub.colorFecha = '#00A2CE'
        break;
      case 'aquarena': 
        objClub.url = require("../img/img-fondo-aquarena.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #FD881D)'
        objClub.colorFecha = '#FD881D'
        break;
      case 'aquatic-gym': 
        objClub.url = require("../img/img-fondo-aquaticgym.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #00A2CE)'
        objClub.colorFecha = '#00A2CE'
        break;
      case 'titanes-jamundi': 
        objClub.url = require("../img/img-fondo-titanesjamundi.webp")
        objClub.colorTitulo = 'linear-gradient(280deg, #ffffff, #00A2CE)'
        objClub.colorFecha = '#00A2CE'
        break;
      default:
        break;
    }
    return objClub;
  }

  function pdfOWeb(vencimiento, resultados, website, pdf, club){
    if(vencimiento){
      resultados = window.open('', '_blank');
      resultados.location.href = require(`../resultados/${pdf.toUpperCase() + ' ' + club}.pdf`);
    }else{
      window.open(website, '_blank');
    }
  }

  function enviarALaPlataforma(club, vencimiento, pdf){
    let resultados;
    switch (club) {
      case 'piranitas':
        pdfOWeb(vencimiento, resultados, 'https://pws-piranitas.web.app', pdf, 'PIRAÑITAS');
        break;
      case 'polares':
        pdfOWeb(vencimiento, resultados, 'https://pws-polares.web.app', pdf, 'POLARES');
        break;
      case 'orcas-valle':
        pdfOWeb(vencimiento, resultados, 'https://www.orcas-valle.com', pdf, 'ORCAS VALLE');
        break;
      case 'linavalle':
        pdfOWeb(vencimiento, resultados, 'https://pws-liga-valle.web.app', pdf, 'LINAVALLE');
        break;
      case 'karina-vivas':
        pdfOWeb(vencimiento, resultados, 'https://pws-karina-vivas.web.app', pdf, 'KARINA VIVAS');
        break;
      case 'fundacion-arbe':
        pdfOWeb(vencimiento, resultados, 'https://pws-arbe.web.app', pdf, 'FUNDACION ARBE');
        break;
      case 'fecna':
        pdfOWeb(vencimiento, resultados, 'https://pws-fecna.web.app', pdf, 'FECNA');
        break;
      case 'atlas-narino':
        pdfOWeb(vencimiento, resultados, 'https://pws-atlas.web.app', pdf, 'ATLAS NARIÑO');
        break;
      case 'leones-acuaticos':
        pdfOWeb(vencimiento, resultados, 'https://pws-leones-acuaticos.web.app', pdf, 'LEONES ACUATICOS');
        break;
      case 'tiburones-blancos':
        pdfOWeb(vencimiento, resultados, 'https://pws-tiburones-blancos.web.app', pdf, 'TIBURONES BLANCOS');
        break;
      case 'makos-palmira':
        pdfOWeb(vencimiento, resultados, 'https://pws-makos.web.app', pdf, 'MAKOS PALMIRA');
        break;
      case 'comfacauca':
        pdfOWeb(vencimiento, resultados, 'https://pws-comfacauca.web.app', pdf, 'COMFACAUCA');
        break;
      case 'dragones-marinos':
        pdfOWeb(vencimiento, resultados, 'https://pws-dragones-marinos.web.app', pdf, 'DRAGONES MARINOS');
        break;
      case 'gold-swimmers':
        pdfOWeb(vencimiento, resultados, 'https://pws-gold-swimmers.web.app', pdf, 'GOLD SWIMMERS');
        break;
      case 'kairos':
        pdfOWeb(vencimiento, resultados, 'https://pws-kairos.web.app', pdf, 'KAIROS');
        break;
      case 'estrellas-del-valle':
        pdfOWeb(vencimiento, resultados, 'https://pws-estrellas-del-valle.web.app', pdf, 'ESTRELLAS DEL VALLE');
        break;
      case 'pacific':
        pdfOWeb(vencimiento, resultados, 'https://pws-pacific.web.app', pdf, 'PACIFIC');
        break;
      case 'aquarena':
        pdfOWeb(vencimiento, resultados, 'https://pwsv-aquarena.web.app', pdf, 'AQUARENA');
        break;
      case 'aquatic-gym':
        pdfOWeb(vencimiento, resultados, 'https://pws-aquatic-gym.web.app', pdf, 'AQUATIC GYM');
        break;
      case 'titanes-jamundi':
        pdfOWeb(vencimiento, resultados, 'https://pws-titanes-jamundi.web.app', pdf, 'TITANES JAMUNDI');
        break;
      default:
        break;
    }
  }

  function manejoRendimiento(record, tiempo){
    if(tiempo && record && record !== '0:0:0' && tiempo !== '0:0:0'){
      const dividirTiempoA = record.split(':');
      const dividirTiempoB = tiempo.split(':');
      const tiempoMinA = dividirTiempoA[0];
      const tiempoSegA = dividirTiempoA[1];
      const tiempoMiliA = dividirTiempoA[2];
      const tiempoMinB = dividirTiempoB[0];
      const tiempoSegB = dividirTiempoB[1];
      const tiempoMiliB = dividirTiempoB[2];
      const tiempoA = parseInt(tiempoMinA) * 60 * 1000 + parseInt(tiempoSegA) * 1000 + parseInt(tiempoMiliA);
      const tiempoB = parseInt(tiempoMinB) * 60 * 1000 + parseInt(tiempoSegB) * 1000 + parseInt(tiempoMiliB);

      const resultadoRecords = parseFloat((tiempoA - tiempoB));
      
      return resultadoRecords;
    }else{
      return 0;
    }
  }

  function arreglarFormatoTiempo(resultadoRecords){
    const auxResultadoRecords = Math.abs(resultadoRecords);
    const minutos = Math.floor(auxResultadoRecords / (60 * 1000));
    const segundos = Math.floor((auxResultadoRecords % (60 * 1000)) / 1000);
    const milisegundos = auxResultadoRecords % 1000;
    if(minutos !== 0){
      return minutos + ':' + segundos + '.' + milisegundos;
    }else{
      return segundos + '.' + milisegundos;
    }
  }

  function sacarHistorialDeportista(nad){
    setDialogHistorial(true);
    const auxHistorial = [];
    resultados.forEach((result)=>{
      const objHistorial = {};
      result.nadadores.forEach((nadad, indexNadad)=>{
        if(nadad.identificacion === nad.identificacion && !nadad.equipo){
          objHistorial.puesto = indexNadad + 1;
          objHistorial.nPrueba = result.id + 1;
          objHistorial.prueba = result.prueba.prueba;
          objHistorial.record = nadad.equipo === null ? nadad.record : '0:0:0';
          objHistorial.tiempo = nadad.tiempo;
          auxHistorial.push(objHistorial);
        }

        if(nadad.equipo){
          const auxNombre = nadad.equipo.filter((eq) => {return eq === nad.nombre});
          if(auxNombre.length !== 0){
            objHistorial.puesto = indexNadad + 1;
            objHistorial.nPrueba = result.id + 1;
            objHistorial.prueba = result.prueba.prueba;
            objHistorial.record = nadad.equipo === null ? nadad.record : '0:0:0';
            objHistorial.tiempo = nadad.tiempo;
            auxHistorial.push(objHistorial);
          }
        }
      })
    })
    nad.pruebas = [...auxHistorial];
    setHistorialDeportista(nad);
  }

  function onClicReaccion(reaccion){
    if(reacciones){
      if(getCookieReaccion('reaxx') !== reaccion){
        if(getCookieReaccion('reaxx') !== ''){
          reacciones[getCookieReaccion('reaxx')] = `${parseInt(reacciones[getCookieReaccion('reaxx')]) - 1}`; 
          reacciones[reaccion] = `${parseInt(reacciones[reaccion]) + 1}`;
        }else{
          reacciones[reaccion] = `${parseInt(reacciones[reaccion]) + 1}`; 
        }
        registrarReaccion(reacciones);
      }
      setReaccionCookie('reaxx', reaccion);
    }
  }

  function onClicTituloTorneo(){
    const idTorneo = document.getElementById('idTorneo').value;
    if(idTorneo !== '' && cliente.length !== 0){
      const auxCliente = cliente.filter((clie) => {return clie.id === idTorneo});
      if(auxCliente.length !== 0){
        traerResultadosFamilia(idTorneo, setResultados, auxCliente[0].club);
        registrarVisualizaciones(auxCliente[0]);
      }
    }
  }

  function mostrarMasInformacion(torneo){
    const alerta = Swal.mixin({
      showConfirmButton: false,
      showCancelButton: true
    })
    alerta.fire({
      title: torneo.titulo,
      imageUrl: torneo.image,
      html: `<table class="table table-bordered table-striped table-hover">
        <tr class="table-dark">
          <th colspan="3">Fechas</th>
        </tr>
        <tr class="table-dark">
          <th>Inicio</th>
          <th>Fin</th>
          <th>Cierre Inscrip.</th>
        </tr>
        <tr class="table-light">
          <td>${torneo.fecha}</td>
          <td>${torneo.fechaF}</td>
          <td>${torneo.fechaInscripciones}</td>
        </tr>
        <tr class="table-dark">
          <th>$ Club</th>
          <th colspan="2">$ Deportista</th>
        </tr>
        <tr class="table-light">
          <td>$${parseInt(torneo.valorClub).toLocaleString()}</td>
          <td colspan="2">$${parseInt(torneo.valorDeportista).toLocaleString()}</td>
        </tr>
        <tr class="table-dark">
          <th>Ciudad</th>
          <th colspan="2">Dirección</th>
        </tr>
        <tr class="table-light">
          <td>${torneo.ciudad}</td>
          <td colspan="2">${torneo.direccion}</td>
        </tr>
        <tr class="table-dark">
          <th colspan="3">Piscina</th>
        </tr>
        <tr class="table-dark">
          <th>Carriles</th>
          <th colspan="2">Distancia</th>
        </tr>
        <tr class="table-light">
          <td>${torneo.carril}</td>
          <td colspan="2">${torneo.piscina !== 'ninguno' ? torneo.piscina + ' Metros' : ''}</td>
        </tr>
      </table>`,
      cancelButtonText: 'Salir',
    })
  }

  function formatNumeroReacciones(number) {
    if (number >= 1e6) {
      // Si es un millón o más, formatea en millones (M)
      return (number / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (number >= 1e3) {
      // Si es mil o más, formatea en miles (k)
      return (number / 1e3).toFixed(1).replace(/\.0$/, '') + 'k';
    } else {
      // Si es menos de mil, devuelve el número original
      return number.toString();
    }
  }

  function onSubmitRestablecerContrasena(e){
    e.preventDefault();
    if(query.get('oobCode')){
      verificarCode(query.get('oobCode')).then(()=>{
        toast.success('Codigo de verificación correcto.');
        const auxNuevaContrasena = nuevaContrasena;
        cambiarPassword(query.get('oobCode'), auxNuevaContrasena, query.get('continueUrl'));
        setDialogNuevaContrasena(false);
        navigate('/');
      }).catch((error)=>{
        switch (error.code) {
          case 'auth/expired-action-code':
            toast.error("El enlace de restablecimiento de contraseña ha expirado. Por favor, solicita un nuevo enlace.");
            break;
          case 'auth/invalid-action-code':
            toast.error("El enlace de restablecimiento de contraseña no es válido o ya ha sido utilizado.");
            break;
          case 'auth/user-disabled':
            toast.error("Esta cuenta ha sido deshabilitada. Contacta al soporte para obtener ayuda.");
            break;
          case 'auth/user-not-found':
            toast.error("No se pudo encontrar una cuenta asociada a este enlace. Verifica tu correo electrónico o intenta nuevamente.");
            break;
          case 'auth/weak-password':
            toast.error("La nueva contraseña es demasiado débil. Asegúrate de que tenga al menos 6 caracteres.");
            break;
          default:
            toast.error("Ocurrió un error inesperado. Por favor, intenta nuevamente.");
        }
      })
    }else{
      toast.error('¡UPS! Ocurrio un error. Intentalo de nuevo.');
    }
    e.target.reset();
  }

  useEffect(() =>{
    traerClientes(setCliente);
    traerReacciones(setReacciones);
    traerTotalVisualizaciones(setTotalVisualizaciones);
  },[])

  useEffect(()=>{
    cliente.forEach((clie, indexClie)=>{
      time(clie, indexClie, fechaGoogle);
      timeLocal(clie.fechaInscripciones, indexClie);
      traerVisualizaciones(clie);
    })
  },[cliente, fechaGoogle])

  useEffect(() =>{
    function nombresClubs(nombre){
      const clubMinus = nombre.toUpperCase();
      let nombreclub = '';
      if (clubMinus.indexOf('-') !== -1) {
        nombreclub = clubMinus.replace(/-/g, " ");
      } else {
        nombreclub = clubMinus;
      }
  
      return nombreclub;
    }

    function acomodarCliente(){
      const auxClientesClub = [];
      cliente.forEach((clie)=>{
        const objCliente = {
          name: nombresClubs(clie.club),
          code: clie.club
        }
        auxClientesClub.push(objCliente);
      })
      setClientesClub(auxClientesClub);
    }

    acomodarCliente();
  },[cliente])

  useEffect(() => {
    // Comparamos los arreglos anterior y actual para detectar cambios de posición
    if (JSON.stringify(resultadosRef.current) !== JSON.stringify(resultados)) {
      // Si hay una diferencia en los arreglos, activamos la animación
      setAnimateList(true);
    }

    // Actualizamos la referencia con el nuevo estado del arreglo
    resultadosRef.current = resultados;

    const animationDuration = 1000; // Duración de la animación en milisegundos (aquí 0.5 segundos)
    const timer = setTimeout(() => {
      setAnimateList(false);
    }, animationDuration);
 
    return () => clearTimeout(timer);
  }, [setAnimateList, resultadosRef, resultados])

  useEffect(() =>{
    if(!dialogResultados){
      cancelarTiemposResultadosFamilia();
      setResultados([]);
    }
  },[dialogResultados])

  useEffect(() =>{
    function sacarNombresClubes(){
      const auxNombres = new Map();
      resultados.forEach((res)=>{
        res.nadadores.forEach((nad)=>{
          const objNombres = {
            name: nad.nombreclub,
            code: nad.club
          }
          auxNombres.set(nad.nombreclub, objNombres);
        })
      })

      setNombresClubs(Array.from(auxNombres.values()));
    }

    sacarNombresClubes();
  },[resultados])

  useEffect(() =>{
    if(query.get('oobCode') && query.get('mode') === 'resetPassword'){
      setDialogNuevaContrasena(true);
      setContadorNewPassword(1);
    }

    if(query.get('oobCode') && query.get('mode') === 'verifyEmail' && contadorQueryQr === 0){
      aplicarVerificacionCorreo(query.get('oobCode'));
      if(query.get('continueUrl')){
        let contador = 10;
        const interval = setInterval(() => {
          if (contador === 0) {
            window.location.href = `${query.get('continueUrl')}`;
            clearInterval(interval);
            contador = 10;
          }
          contador--;
        }, 1000);
      }
      setContadorQueryQr(1);
      navigate('/');
    }
  },[query])

  return (
    <>
    {/*
      <motion.div className="text-center position-relative"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <Accordion activeIndex={0}>
          <AccordionTab header="¿Qué opinas de nuestra plataforma?">
            <div className="d-flex justify-content-around mt-2 m-auto" style={{width: '200px'}}>
              <div className="position-relative">
                <img className='img-reaccion' src={require('../img/gif-meencanta.gif')} alt="emoji" onClick={() => onClicReaccion('meencanta')} style={{cursor:'pointer', width: '50px'}}/>
                <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'meencanta' ? 'bg-danger' : 'bg-success'}`} style={{top: '6px'}}>
                  {reacciones ? formatNumeroReacciones(parseInt(reacciones.meencanta)) : '0'}
                </span>
              </div>
              <div className="position-relative">
                <img className='img-reaccion' src={require('../img/gif-like.gif')} alt="emoji" onClick={() => onClicReaccion('like')} style={{cursor:'pointer', width: '50px'}}/>
                <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'like' ? 'bg-danger' : 'bg-success'}`} style={{top: '6px'}}>
                  {reacciones ? formatNumeroReacciones( parseInt(reacciones.like)) : '0'}
                </span>
              </div>
              <div className="position-relative" style={{top: '12px'}}>
                <img className='img-reaccion' src={require('../img/gif-dislike.gif')} alt="emoji" onClick={() => onClicReaccion('dislike')} style={{cursor:'pointer', width: '50px'}}/>
                <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'dislike' ? 'bg-danger' : 'bg-success'}`} style={{top: '-4px'}}>
                  {reacciones ? formatNumeroReacciones(parseInt(reacciones.dislike)) : '0'}
                </span>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </motion.div>
    */}
      <motion.div className='bg-light position-relative border-bottom border-dark'
      initial={{opacity: 0}}
      whileInView={{opacity: 1}}
      transition={{duration: 1.2, delay: 0.3}}
      >
        <ul className="circles" style={{zIndex: 0}}>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div id="carouselExampleCaptions1" className="carousel carousel-dark slide m-auto rounded-3 rounded-bottom shadow" data-bs-ride="carousel" style={{maxWidth: '1000px'}}>
          <div className="carousel-inner position-relative">
            {imagenesPortada.map((img, indexImg)=>{
              return <div key={indexImg} className={`carousel-item ${indexImg === 0 ? 'active' : ''}`} data-bs-interval="5000" style={{height: '50vw', maxHeight: '500px'}}>
              <div className='h-100' style={{background: `url(${img.image}) center/cover no-repeat`}}></div>
            </div>
            })}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <div className='position-absolute top-0 d-flex align-items-center bg-light fs-1 shadow' style={{borderRadius: '0 0 10px 0', zIndex: 2}}>
          <Link to="mailto:pws@prowebsports.com.co" target='_blank' style={{textDecoration: 'none'}}><IoMdMail className='text-secondary p-1 border-end'/></Link>
          <Link to="https://wa.me/573245884041" target='_blank' style={{textDecoration: 'none'}}><IoLogoWhatsapp className='text-success p-1 border-end'/></Link>
          <Link to="https://t.me/573245884041" target='_blank' style={{textDecoration: 'none'}}><IoIosPaperPlane className='text-info p-1 border-end'/></Link>
          <Link to="https://www.facebook.com/emanuel.barbosa.908132" target='_blank' style={{textDecoration: 'none'}}><IoLogoFacebook className='text-primary p-1 border-end'/></Link>
          <Link to="https://www.instagram.com/prowebsports/" target='_blank' style={{textDecoration: 'none'}}><IoLogoInstagram className='p-1 border-end' style={{color: 'purple'}}/></Link>
          <Link to="https://www.youtube.com/@emanuelbarbosa2735" target='_blank' style={{textDecoration: 'none'}}><IoLogoYoutube className='text-danger p-1'/></Link>
        </div>
      </motion.div>
      <motion.div className='d-flex justify-content-start'
      initial={{x: -200}}
      whileInView={{x: 0}}
      transition={{duration: 1}}>
        <h3 className='m-0 text-start bg-light p-2 border-bottom shadow' style={{fontFamily: 'Anton SC', display: 'inline-block', borderRadius: '0 0 10px 0'}}>PRÓXIMOS EVENTOS</h3>
      </motion.div>
      <motion.div className="d-flex flex-wrap justify-content-center align-items-start position-relative" id="torneos" style={{zIndex: 100}}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{duration: 1}}
      >
        <div className="wave-button-container" style={{bottom: '75px', right: '0', zIndex: 10000, width: '90px'}}>
          <Button icon='pi pi-bolt' className='shadow wave-buttonResultados' size="large" severity='danger' tooltip='Resultados en tiempo real' tooltipOptions={{position: 'left', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}}
          onClick={() =>setDialogResultados(true)}></Button>
        </div>
        <div className="wave-button-container" style={{bottom: '10px', right: '0', zIndex: 10000, width: '90px'}}>
          <Button icon='pi pi-calendar' className='shadow wave-buttonCalendar' size="large" severity='danger' tooltip='Calendario' tooltipOptions={{position: 'left', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}}
          onClick={() =>setDialogCalendario(true)}></Button>
        </div>
        {/*
        <div className="wave-button-container" style={{bottom: '140px', right: '0', zIndex: 10000, width: '90px'}}>
          <Button icon='pi pi-dollar' className='shadow wave-button' size="large" severity='success' tooltip='Cotizar' tooltipOptions={{position: 'left', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}}
          onClick={() =>setDialogCotizacion(true)}></Button>
        </div>
        */}
        {cliente.length !== 0 ?(
          cliente.filter((clie)=> clie.estado !== 'terminado').filter((clie)=>clie.club.includes(selectedClub?.code ? selectedClub.code : '')).map((torn, indexTorn)=>{
            return <motion.div
            className={`position-relative card-torneos card m-1 p-0 shadow ${torn.vencimiento ? 'no-activo' : 'activo'}`}
            style={{ width: '19rem'}}
            key={indexTorn}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: `0.${indexTorn}5` }}
          >
            <span className='linea linea-left'></span>
            <span className='linea linea-right'></span>
            <span className='linea linea-top'></span>
            <span className='linea linea-bottom'></span>
            <div className='position-relative'>
              <div className='d-flex justify-content-center align-items-center position-relative' style={{background: `${traerImagenTorneo(torn.club).colorTitulo}`, height: '272px'}}>
                <PixelCard variant="default">
                  <img className='position-absolute' src={traerImagenTorneo(torn.club).url} alt="logo" style={{cursor: 'pointer', width: '200px', height: '200px', objectFit: 'contain', filter: "drop-shadow(-1px 1px 1px black)"}} onClick={() => enviarALaPlataforma(torn.club, torn.vencimiento, torn.titulo)}/>
                </PixelCard>
                <div className='position-absolute start-0' style={{top: '27px'}}>
                  <p className='bg-light p-1 fs-6 border-top border-bottom' style={{borderRadius: '0 0 10px 0'}}>{torn.ciudad}</p>
                </div>
                <div className='d-flex flex-column position-absolute start-0' style={{fontFamily: 'Fira Sans Extra Condensed', fontSize: '13px', top: '61px'}}>
                  <i className='pi pi-map-marker text-warning bg-light p-1 fs-5 border-bottom' style={{cursor: 'pointer', borderRadius: '0 10px 10px 0'}} onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(torn.direccion)}`, '_blank')}></i>
                  {torn.convocatoria !== '' ?(
                    <i className='pi pi-file-pdf text-danger bg-light p-1 fs-5 border-bottom' style={{cursor: 'pointer', borderRadius: '0 10px 10px 0'}} onClick={() => window.open(`${torn.convocatoria}`, '_blank')}></i>
                  ):( 
                    <></>
                  )}
                  <i className='pi pi-briefcase text-secondary bg-light p-1 fs-5 border-bottom' style={{cursor: 'pointer', borderRadius: '0 10px 10px 0'}} onClick={() => mostrarMasInformacion(torn)}></i>
                </div>
                <div className='d-flex position-absolute start-0 bg-light ps-1 pe-1 shadow' style={{fontFamily: 'Fira Sans Extra Condensed', fontSize: '13px', borderRadius: '0 10px 0 0', bottom: '32px'}}> 
                  <p className='m-0' id={`inscripcionesCerradas${torn.club}`}></p>
                  <div id={`contenedor-fecha${torn.club}`}>
                    <span>Cierre en </span>
                    <span id={`dia${torn.club}`}>00</span>
                    <span id={`dia-hora${torn.club}`}></span><span>⏱️</span> 
                  </div>
                </div>
                <div className="position-absolute bottom-0 start-0 ps-2 p-1 text-start text-light" style={{background: `${traerImagenTorneo(torn.club).colorFecha}`, borderRadius: '0 10px 0 0'}}>
                  <div id={`marquee${torn.club}`} style={{fontFamily: 'Fira Sans Extra Condensed', fontSize: '16px'}}>
                    <span id={`marqueeSpan${torn.club}`}></span>
                  </div>
                </div>
                <img className='position-absolute' src={require('../img/img-logo-swimmingt.webp')} alt="logo" style={{width: '40px', right: '4px', bottom: '35px'}}/>
              </div>
              <div className="w-100 position-absolute top-0 text-dark bg-light shadow">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <img
                      className="mt-1 ms-2"
                      style={{ width: "17px", marginBottom: '1px' }}
                      src={require("../img/icon-usuario-curso.webp")}
                      alt="icono del usuario"
                      id={`cantNadadores${indexTorn}`}
                    />
                    <p
                      id={`cantNadadores${indexTorn}`}
                      className='mt-1 m-0'
                      style={{ fontSize: "15px", fontFamily: "Anton SC", letterSpacing: '1px' }}
                    >
                      <CountUp
                        from={0}
                        to={parseInt(torn.deportistas)}
                        separator=","
                        direction="up"
                        duration={1}
                        className=""
                      />
                    </p>
                    <p
                      id={`cantVisualizaciones${indexTorn}`}
                      className='ms-1 mt-1 m-0'
                      style={{ fontSize: "15px", fontFamily: "Anton SC", letterSpacing: '1px' }}
                    > 
                      <i className='pi pi-eye' style={{marginRight: '2px'}}></i>
                      <CountUp
                        from={0}
                        to={parseInt(torn.visualizaciones) || 0}
                        separator=","
                        direction="up"
                        duration={1}
                        className=""
                      />
                    </p>
                  </div>
                  <div className='text-end mt-1 me-2' id={`contenedor-diaLocal${indexTorn}`} style={{fontFamily: 'Fira Sans Extra Condensed', fontSize: '15px', fontWeight: 'bold'}}> 
                    <span>Cierre en </span>
                    <span id={`diaLocal${indexTorn}`}>00</span><span>D : </span>
                    <span id={`horaLocal${indexTorn}`}>00</span><span>H : </span>
                    <span id={`minutoLocal${indexTorn}`}>00</span><span>M : </span>
                    <span id={`segundoLocal${indexTorn}`}>00</span><span>S</span> 
                  </div>
                </div>
              </div>
              <Mensaje
                anchorId={`cantNadadores${indexTorn}`}
                place="bottom"
                content={`Inscritos`}
                className="bg-primary shadow"
                style={{ zIndex: 1}}
              />
              <Mensaje
                anchorId={`cantVisualizaciones${indexTorn}`}
                place="bottom"
                content={`Visualizaciones`}
                className="bg-danger shadow"
                style={{ zIndex: 1}}
              />
            </div>
            <div className='card-footer text-start p-1' style={{fontFamily: 'Fira Sans Extra Condensed', fontSize: '15px'}}>
              <p className='m-0'>Jornada 01, {arreglarFecha(torn.fecha)}📅</p>
            </div>
          </motion.div>
          })
        ):(
          <> </>
        )}
      </motion.div>
      <div className='position-relative bg-dark'>
        <ul className="circles" style={{zIndex: 0}}>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className='p-1 pt-3 position-relative'>
          <h2 className='mb-0' style={{fontFamily: 'Anton SC', lineHeight: '1.1'}}>
            <GradientText
              colors={["#fffb00", "#00cdff", "#ff8080", "#fffb00", "#00cdff"]}
              animationSpeed={8}
              showBorder={false}
              className="custom-class"
            >
              CÓMO NOS VEN Y QUÉ OPINAN
            </GradientText>
          </h2>
        </div>
        <div className='d-flex flex-wrap justify-content-around pb-5 p-1'>
          <motion.div className='d-flex flex-column m-1' 
            style={{height: '100px', zIndex: 1}}
            initial={{y: -50, opacity: 0}}
            whileInView={{y: 0, opacity: 1}}
            transition={{duration: 1.2, delay: 0.3}}
          >
            <div className='d-flex justify-content-center align-items-center' style={{fontFamily: 'Anton SC'}}>
              <i className='pi pi-eye text-warning fs-4'></i>
              <CountUp
                from={0}
                to={totalVisualizaciones}
                separator=","
                direction="up"
                duration={1}
                className="text-light fs-1 ms-2"
              />
            </div>
            <p className='m-0 m-auto text-muted w-75' style={{fontFamily: 'Merriweather Sans', lineHeight: '1.2'}}>Accesos a resultados en tiempo real.</p>
            <hr className='text-light'/>
          </motion.div>
          <motion.div className='d-flex flex-column m-1' 
          style={{height: '100px', zIndex: 1}}
          initial={{y: -50, opacity: 0}}
          whileInView={{y: 0, opacity: 1}}
          transition={{duration: 1.2, delay: 0.3}}
          >
            <div className='d-flex justify-content-center align-items-center' style={{fontFamily: 'Anton SC'}}>
              <i className='pi pi-heart-fill text-danger fs-4'></i>
              <CountUp
                from={0}
                to={reacciones ? parseInt(reacciones.meencanta) : 0}
                separator=","
                direction="up"
                duration={1}
                className="text-light fs-1 ms-2"
              />
            </div>
            <p className='m-0 m-auto text-muted w-75' style={{fontFamily: 'Merriweather Sans', lineHeight: '1.2'}}>Aman nuestras plataformas.</p>
            <hr className='text-light'/>
          </motion.div>
          <motion.div className='d-flex flex-column m-1' style={{height: '100px', zIndex: 1}}
          initial={{y: -50, opacity: 0}}
          whileInView={{y: 0, opacity: 1}}
          transition={{duration: 1.2, delay: 0.4}}
          >
            <div className='d-flex justify-content-center align-items-center' style={{fontFamily: 'Anton SC'}}>
              <i className='pi pi-thumbs-up-fill text-primary fs-4'></i>
              <CountUp
                from={0}
                to={reacciones ? parseInt(reacciones.like) : 0}
                separator=","
                direction="up"
                duration={1}
                className="text-light fs-1 ms-2"
              />
            </div>
            <p className='m-0 m-auto text-muted w-75' style={{fontFamily: 'Merriweather Sans', lineHeight: '1.2'}}>Les gusta la plataforma.</p>
            <hr className='text-light'/>
          </motion.div>
          <motion.div className='d-flex flex-column m-1' style={{height: '100px', zIndex: 1}}
          initial={{y: -50, opacity: 0}}
          whileInView={{y: 0, opacity: 1}}
          transition={{duration: 1.2, delay: 0.5}}
          >
            <div className='d-flex justify-content-center align-items-center' style={{fontFamily: 'Anton SC'}}>
              <i className='pi pi-thumbs-down-fill text-primary fs-4'></i>
              <CountUp
                from={0}
                to={reacciones ? parseInt(reacciones.dislike) : 0}
                separator=","
                direction="up"
                duration={1}
                className="text-light fs-1 ms-2"
              />
            </div>
            <p className='m-0 m-auto text-muted w-75' style={{fontFamily: 'Merriweather Sans', lineHeight: '1.2'}}>Aún hay oportunidades de mejora.</p>
            <hr className='text-light'/>
          </motion.div>
        </div>
      </div>
      <motion.div className='d-flex justify-content-start'
      initial={{x: -200}}
      whileInView={{x: 0}}
      transition={{duration: 1}}>
        <h3 className='m-0 text-start bg-light p-2 border-bottom shadow' style={{fontFamily: 'Anton SC', display: 'inline-block', borderRadius: '0 0 10px 0'}}>RESULTADOS</h3>
      </motion.div>
      <motion.div className="d-flex flex-wrap justify-content-center align-items-start position-relative" id="resultados" style={{zIndex: 100}}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}> 
        {cliente.length !== 0 ?(
          cliente.filter((clie)=> clie.estado === 'terminado').filter((clie)=>clie.club.includes(selectedClub?.code ? selectedClub.code : '')).map((torn, indexTorn)=>{
            return <motion.div
            className={`position-relative card-torneos card m-1 p-0 shadow ${torn.vencimiento ? 'no-activo' : 'activo'}`}
            style={{ width: '19rem'}}
            key={indexTorn}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: `0.${indexTorn}5` }}
          >
            <span className='linea linea-left'></span>
            <span className='linea linea-right'></span>
            <span className='linea linea-top'></span>
            <span className='linea linea-bottom'></span>
            <div className='position-relative'>
              <div className='d-flex justify-content-center align-items-center position-relative' style={{background: `${traerImagenTorneo(torn.club).colorTitulo}`, height: '272px'}}>
                <PixelCard variant="default">
                  <img className='position-absolute' src={traerImagenTorneo(torn.club).url} alt="logo" style={{cursor: 'pointer', width: '200px', height: '200px', objectFit: 'contain', filter: "drop-shadow(-1px 1px 1px black)"}} onClick={() => enviarALaPlataforma(torn.club, torn.vencimiento, torn.titulo)}/>
                </PixelCard>
                <div className='position-absolute start-0' style={{top: '27px'}}>
                  <p className='bg-light p-1 fs-6 border-top border-bottom' style={{borderRadius: '0 0 10px 0'}}>{torn.ciudad}</p>
                </div>
                <div className='d-flex flex-column position-absolute start-0' style={{fontFamily: 'Fira Sans Extra Condensed', fontSize: '13px', top: '61px'}}>
                  <i className='pi pi-map-marker text-warning bg-light p-1 fs-5 border-bottom' style={{cursor: 'pointer', borderRadius: '0 10px 10px 0'}} onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(torn.direccion)}`, '_blank')}></i>
                  {torn.convocatoria !== '' ?(
                    <i className='pi pi-file-pdf text-danger bg-light p-1 fs-5 border-bottom' style={{cursor: 'pointer', borderRadius: '0 10px 10px 0'}} onClick={() => window.open(`${torn.convocatoria}`, '_blank')}></i>
                  ):( 
                    <></>
                  )}
                  <i className='pi pi-briefcase text-secondary bg-light p-1 fs-5 border-bottom' style={{cursor: 'pointer', borderRadius: '0 10px 10px 0'}} onClick={() => mostrarMasInformacion(torn)}></i>
                </div>
                <div className='d-flex position-absolute start-0 bg-light ps-1 pe-1 shadow' style={{fontFamily: 'Fira Sans Extra Condensed', fontSize: '13px', borderRadius: '0 10px 0 0', bottom: '32px'}}> 
                  <p className='m-0' id={`inscripcionesCerradas${torn.club}`}></p>
                  <div id={`contenedor-fecha${torn.club}`}>
                    <span>Cierre en </span>
                    <span id={`dia${torn.club}`}>00</span>
                    <span id={`dia-hora${torn.club}`}></span><span>⏱️</span> 
                  </div>
                </div>
                <div className="position-absolute bottom-0 start-0 ps-2 p-1 text-start text-light" style={{background: `${traerImagenTorneo(torn.club).colorFecha}`, borderRadius: '0 10px 0 0'}}>
                  <div id={`marquee${torn.club}`} style={{fontFamily: 'Fira Sans Extra Condensed', fontSize: '16px'}}>
                    <span id={`marqueeSpan${torn.club}`}></span>
                  </div>
                </div>
                <img className='position-absolute' src={require('../img/img-logo-swimmingt.webp')} alt="logo" style={{width: '40px', right: '4px', bottom: '35px'}}/>
              </div>
              <div className="w-100 position-absolute top-0 text-dark bg-light shadow">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <img
                      className="mt-1 ms-2"
                      style={{ width: "17px", marginBottom: '1px' }}
                      src={require("../img/icon-usuario-curso.webp")}
                      alt="icono del usuario"
                      id={`cantNadadores${indexTorn}`}
                    />
                    <p
                      id={`cantNadadores${indexTorn}`}
                      className='mt-1 m-0'
                      style={{ fontSize: "15px", fontFamily: "Anton SC", letterSpacing: '1px' }}
                    >
                      <CountUp
                        from={0}
                        to={parseInt(torn.deportistas)}
                        separator=","
                        direction="up"
                        duration={1}
                        className=""
                      />
                    </p>
                    <p
                      id={`cantVisualizaciones${indexTorn}`}
                      className='ms-1 mt-1 m-0'
                      style={{ fontSize: "15px", fontFamily: "Anton SC", letterSpacing: '1px' }}
                    > 
                      <i className='pi pi-eye' style={{marginRight: '2px'}}></i>
                      <CountUp
                        from={0}
                        to={parseInt(torn.visualizaciones) || 0}
                        separator=","
                        direction="up"
                        duration={1}
                        className=""
                      />
                    </p>
                  </div>
                </div>
              </div>
              <Mensaje
                anchorId={`cantNadadores${indexTorn}`}
                place="bottom"
                content={`Inscritos`}
                className="bg-primary shadow"
                style={{ zIndex: 1}}
              />
              <Mensaje
                anchorId={`cantVisualizaciones${indexTorn}`}
                place="bottom"
                content={`Visualizaciones`}
                className="bg-danger shadow"
                style={{ zIndex: 1}}
              />
            </div>
            <div className='card-footer text-start p-1' style={{fontFamily: 'Fira Sans Extra Condensed', fontSize: '15px'}}>
              <p className='m-0'>Jornada 01, {arreglarFecha(torn.fecha)}📅</p>
            </div>
          </motion.div>
          })
        ):(
          <> </>
        )}
      </motion.div>
      <motion.div
      className='bg-dark position-relative'
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <ul className="circles" style={{zIndex: 0}}>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className='border-top p-3' id="inicio">
          <div className='text-center m-auto' style={{maxWidth: '1000px'}}>
            <motion.div className='p-2'
            initial={{opacity: 0}}
            whileInView={{opacity: 1}}
            transition={{duration: 1.2}}
            >
              <h2 style={{fontFamily: 'Anton SC', lineHeight: '1.1'}}>
                <GradientText
                  colors={["#fffb00", "#00cdff", "#ff8080", "#fffb00", "#00cdff"]}
                  animationSpeed={8}
                  showBorder={false}
                  className="custom-class"
                >
                  ALQUILAMOS PLATAFORMAS DIGITALES PARA LA ADMINISTRACIÓN DE COMPETENCIAS DE NATACIÓN, INCLUYENDO EL USO DE CRONOSPORTS COMO SISTEMA DE CRONOMETRAJE DIGITAL
                </GradientText>
              </h2>
              <p className='m-0 text-muted' style={{fontFamily: 'Merriweather Sans', textAlign: 'justify', lineHeight: '1.2'}}>Brindamos soluciones digitales para la administración de competencias de natación, simplificando el registro, seguimiento y presentación de resultados en tiempo real. Este año, estamos lanzando CRONOSPORTS en fase piloto, un sistema de cronometraje digital que automatiza el registro de tiempos, mejorando la precisión y eficiencia de los eventos. Durante esta etapa piloto, el uso de CRONOSPORTS será completamente gratuito.</p>
            </motion.div>
          </div>
        </div>
        <hr className='text-light m-0 ps-2 pe-2'/>
        <div>
          <motion.div id="carouselExampleCaptions" className="carousel carousel-dark slide m-auto rounded-3 rounded-bottom shadow" data-bs-ride="carousel" style={{maxWidth: '1000px'}}
          initial={{opacity: 0}}
          whileInView={{opacity: 1}}
          transition={{duration: 1.2}}
          >
            <div className="carousel-inner position-relative">
              {imagenes.map((img, indexImg)=>{
                return <div key={indexImg} className={`carousel-item ${indexImg === 0 ? 'active' : ''}`} data-bs-interval="8000" style={{height: '50vw', maxHeight: '500px'}}>
                <div className='h-100' style={{background: `url(${img.image}) center/cover no-repeat`}}></div>
                <div className="carousel-caption d-none d-md-block position-absolute bottom-0 start-0 text-start p-1" style={{maxWidth: '700px', background: 'linear-gradient(-60deg, #abc7da 50%, rgb(219, 231, 240) 50%)', borderRadius: '0 10px 0 0'}}>
                  <h5 className='mb-0' style={{fontFamily: 'Anton SC'}}>{img.titulo}</h5>
                  <hr className='m-1'/>
                  <p className='m-0' style={{fontFamily: 'Fira Sans Extra Condensed', lineHeight: '1.1'}}>{img.descripcion}</p> 
                </div>
              </div>
              })}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </motion.div>
        </div>
        <hr className='text-light m-0 ps-2 pe-2'/>
        <div className='mt-3 position-relative' id='precios'>
          <motion.h2 style={{fontFamily: 'Anton SC', lineHeight: '1.1'}}
          initial={{opacity: 0}}
          whileInView={{opacity: 1}}
          transition={{duration: 1.2}}>
            <GradientText
              colors={["#fffb00", "#00cdff", "#ff8080", "#fffb00", "#00cdff"]}
              animationSpeed={8}
              showBorder={false}
              className="custom-class"
            >
              PAQUETES PROWEBSPORTS 2025
            </GradientText>
          </motion.h2>
          <div className='d-flex flex-wrap justify-content-center'>
            <motion.div className='bg-light m-2 rounded-3 shadow' style={{width: '250px', height: '500px'}}
            initial={{opacity: 0}}
            whileInView={{opacity: 1}}
            transition={{duration: 1.2}}>
              <h3 className='mt-2' style={{fontFamily: 'Anton SC', lineHeight: '1.1'}}>
                <GradientText
                  colors={["#0057ff", "#00cdff", "#adadad", "#0057ff", "#00cdff"]}
                  animationSpeed={8}
                  showBorder={false}
                  className="custom-class"
                >
                  BÁSICO
                </GradientText>
              </h3>
              <hr className='m-0 ps-2 pe-2'/>
              <div className='text-center p-2 shadow' style={{background: 'rgba(163, 221, 251)'}}>
                <h4 style={{fontFamily: 'Anton SC', lineHeight: '1.1'}}>CLUB: COP 3.500</h4>
                <h4 style={{fontFamily: 'Anton SC', lineHeight: '1.1'}}>EMPRESA: COP 4.000</h4>
              </div>
              <div className='text-start pt-2'>
                <ul>
                  <li>Inscripciones online</li>
                  <li>Autorizaciones de ingreso</li>
                  <li>Control de equipos</li>
                  <li>Estadísticas de categorías</li>
                  <li>Programación</li>
                  <li>Registro de tiempos</li>
                  <li>Resultados en tiempo real</li>
                  <li>Puntuaciones (Trofeo)</li>
                  <li>Rifas</li>
                  <li>Y mas...</li>
                </ul>
              </div>
            </motion.div>
            <motion.div className='bg-light m-1 rounded-3 shadow' style={{width: '300px'}}
            initial={{opacity: 0}}
            whileInView={{opacity: 1}}
            transition={{duration: 1.5}}
            >
              <h3 className='mt-2' style={{fontFamily: 'Anton SC', lineHeight: '1.1'}}>
                <GradientText
                  colors={["#ff8e00", "#f9c079", "#adadad", "#ff8e00", "#f9c079"]}
                  animationSpeed={8}
                  showBorder={false}
                  className="custom-class"
                >
                  CRONOSPORTS
                </GradientText>
              </h3>
              <hr className='m-0 ps-2 pe-2'/>
              <div className='text-center p-2 shadow' style={{background: 'rgba(251, 209, 163)'}}>
                <h4 style={{fontFamily: 'Anton SC', lineHeight: '1.1'}}>CLUB: COP 3.500</h4>
                <h4 style={{fontFamily: 'Anton SC', lineHeight: '1.1'}}>EMPRESA: COP 4.000</h4>
              </div>
              <div className='text-start pt-2'>
                <ul>
                  <li>Inscripciones online</li>
                  <li>Autorizaciones de ingreso</li>
                  <li>Control de equipos</li>
                  <li>Estadísticas de categorías</li>
                  <li>Programación</li>
                  <li>Registro de tiempos</li>
                  <li>Resultados en tiempo real</li>
                  <li>Puntuaciones (Trofeo)</li>
                  <li>Rifas</li>
                  <li>Y mas...</li>
                </ul>
              </div>
              <div className='text-center p-2 shadow' style={{background: 'rgba(251, 209, 163)'}}>
                <h5 style={{fontFamily: 'Anton SC', lineHeight: '1.1'}}><i className='pi pi-plus fs-5'></i> COP 25.000 POR DISPOSITIVO Y POR JORNADA</h5>
              </div>
              <div className='text-start pt-2'>
                <ul>
                  <li>Operador incluido <b>(Se le debe dar la respetiva alimentación)</b></li>
                  <li>Descuento de <b>COP 200</b> por deportista inscrito</li>
                </ul>
              </div>
              <hr className='m-0 ps-2 pe-2'/>
              <div className='text-start p-1 pb-0'>
                <p style={{fontFamily: 'Acme', lineHeight: '1.1'}}>Los descuentos solo se aplican si el evento tiene menos de 100 deportistas inscritos</p>
              </div>
            </motion.div>
            <motion.div className='bg-light m-2 rounded-3 shadow' style={{width: '270px', height: '500px'}}
            initial={{opacity: 0}}
            whileInView={{opacity: 1}}
            transition={{duration: 1.8}}>
              <h3 className='mt-2' style={{fontFamily: 'Anton SC', lineHeight: '1.1'}}>
                <GradientText
                  colors={["#fe0000", "#ff6767", "#adadad", "#fe0000", "#ff6767"]}
                  animationSpeed={8}
                  showBorder={false}
                  className="custom-class"
                >
                  DIGITADOR
                </GradientText>
              </h3>
              <hr className='m-0 ps-2 pe-2'/>
              <div className='text-center p-2 shadow' style={{background: 'rgba(251, 163, 163)'}}>
                <h4 style={{fontFamily: 'Anton SC', lineHeight: '1.1'}}>CLUB: COP 3.500</h4>
                <h4 style={{fontFamily: 'Anton SC', lineHeight: '1.1'}}>EMPRESA: COP 4.000</h4>
              </div>
              <div className='text-start pt-2'>
                <ul>
                  <li>Inscripciones online</li>
                  <li>Autorizaciones de ingreso</li>
                  <li>Control de equipos</li>
                  <li>Estadísticas de categorías</li>
                  <li>Programación</li>
                  <li>Registro de tiempos</li>
                  <li>Resultados en tiempo real</li>
                  <li>Puntuaciones (Trofeo)</li>
                  <li>Rifas</li>
                  <li>Y mas...</li>
                </ul>
              </div>
              <div className='text-center p-2 shadow' style={{background: 'rgba(251, 163, 163)'}}>
                <h5 style={{fontFamily: 'Anton SC', lineHeight: '1.1'}}><i className='pi pi-plus fs-5'></i> COP 150.000 POR JORNADA</h5>
              </div>
              <hr className='m-0 ps-2 pe-2'/>
              <div className='text-start p-1 pb-0'>
                <p style={{fontFamily: 'Acme', lineHeight: '1.1'}}>Se le debe dar la respetiva alimentación al operador</p>
              </div>
            </motion.div>
          </div>
        </div>
        <hr className='text-light m-0 ps-2 pe-2'/>
        <div>
          <motion.div className='m-auto position-relative activo' style={{maxWidth: '1000px'}}
          initial={{opacity: 0}}
          whileInView={{opacity: 1}}
          transition={{duration: 1.2}}
          >
            <span className='linea linea-left'></span>
            <span className='linea linea-right'></span>
            <span className='linea linea-top'></span>
            <span className='linea linea-bottom'></span>
            <div style={{padding:'56.25% 0 0 0', position: 'relative'}}>
              <iframe src="https://player.vimeo.com/video/1055017030?h=f8e0322d7c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" style={{position:'absolute', top:0, left:0, width:'100%', height: '100%'}} title="prowebsports"></iframe>
            </div>
          </motion.div>
        </div>
      </motion.div>
      {/*
      <div className='m-auto' style={{maxWidth: '1400px'}}>
        <motion.div className='mt-2 ms-4 d-flex justify-content-start align-items-center'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{duration: 1}}
        style={{textAlign: 'justify'}}
        >
          <div className='w-50' style={{maxWidth: '150px'}}>
            <img className='w-100' src={require('../img/vision.png')} alt="logo vision" style={{filter: "drop-shadow(-1px 1px 2px black)"}}/>
          </div>
          <div className='w-100 ms-1' style={{maxWidth: '400px'}}>
            <h3 className='mb-0' style={{fontFamily: 'Anton SC'}}>VISIÓN:</h3>
            <p style={{fontFamily: 'Fira Sans Extra Condensed', lineHeight: '15px'}}>
            Nuestra Visión en <b>PROWEBSPORTS</b> es liderar globalmente en gestión de torneos de natación, brindando una solución tecnológica innovadora que ofrece una experiencia de usuario única y soporte técnico de excelencia. Queremos transformar el deporte, eliminando el papeleo y creando un entorno digital eficiente, confiable y sostenible para entrenadores, nadadores y aficionados.
            </p>
          </div>
        </motion.div>
        <motion.div className='mt-1 me-4 d-flex justify-content-end align-items-center'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{duration: 1}}
        style={{textAlign: 'justify'}}
        >
          <div className='w-100 me-1' style={{maxWidth: '400px'}}>
            <h3 className='mb-0' style={{fontFamily: 'Anton SC'}}>MISIÓN:</h3>
            <p style={{fontFamily: 'Fira Sans Extra Condensed', lineHeight: '15px'}}>
            Nuestra Misión en <b>PROWEBSPORTS</b> es revolucionar la natación mediante soluciones tecnológicas avanzadas que simplifiquen la organización de torneos. Nos dedicamos a ofrecer herramientas innovadoras para entrenadores, mejorando la experiencia deportiva y optimizando cada etapa del proceso para todos los involucrados.
            </p>
          </div>
          <div className='w-50' style={{maxWidth: '150px'}}>
            <img className='w-100' src={require('../img/mision.png')} alt="logo mision" style={{filter: "drop-shadow(-1px 1px 2px black)"}}/>
          </div>
        </motion.div>
      </div>
      */}
      <div className='bg-dark' id='ranking'>
        {consultarRanking ? (
          <div>
            <motion.h2 className='mb-0 pt-5 p-3' style={{fontFamily: 'Anton SC', lineHeight: '1.1'}}
            initial={{opacity: 0}}
            whileInView={{opacity: 1}}
            transition={{duration: 1.2}}
            >
              <GradientText
                colors={["#fffb00", "#00cdff", "#ff8080", "#fffb00", "#00cdff"]}
                animationSpeed={8}
                showBorder={false}
                className="custom-class"
              >
                RANKING PROWEBSPORTS
              </GradientText>
            </motion.h2>
            <Records
              recordsMundial={recordsMundial}
              recordsNacional={recordsNacional}/>
          </div> 
        ):(
          <div className='pt-3 w-100 position-relative'>
            <img className='w-100' src={require('../img/img-ranking.png')} alt="img ranking" />
            <div className='w-100 h-100 position-absolute top-0' style={{ background: 'rgba(131, 131, 131, 0.8)', zIndex: 1}}></div>
            <Button className='btn btn-warning shadow position-absolute top-50 start-50 translate-middle' style={{zIndex: 2}} icon="pi pi-crown" label='Consultar Ranking' onClick={() => setConsultarRanking(true)}/>
          </div>
        )}
      </div>
      <Dialog visible={dialogResultados} style={{ width: '100%' }} header="Resultados en tiempo real" modal className="p-fluid" onHide={() => setDialogResultados(false)}>
      <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <h4>¡IMPORTANTE!</h4>
          <p className="mb-0">¡Hola a todos! Les recordamos que no es necesario recargar la plataforma, ya que los resultados se actualizan en tiempo real. ¡Gracias por su colaboración y paciencia!</p>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div className="form-floating mb-2">
          <select name="idTorneo" id="idTorneo" className='form-select' onChange={() => onClicTituloTorneo()}>
            <option value="">Elige el evento</option>
            {cliente.length !== 0 ? (
              cliente.map((torn,indexTorn)=>{
                return <option key={indexTorn} value={torn.id}>{`${torn.estado === 'evento' ? torn.titulo + ' ' + arreglarNombresCortos(torn.club) + ' - En curso...' : torn.estado === 'inscripciones' ? torn.titulo + ' ' + arreglarNombresCortos(torn.club) + ' - No ha dado inicio aún.' : torn.titulo + ' ' + arreglarNombresCortos(torn.club) + ' - ' + torn.estado}`}</option>
              }))
              :(
              <option value="">Sin eventos aún</option>
              )
            }
          </select>
          <label htmlFor="idTorneo">Eventos</label>
        </div>
        {resultados.length !== 0 ?(
          <div className="d-flex flex-wrap justify-content-center align-items-center mb-1">
            <span className="p-input-icon-left" style={{width: '220px'}}>
              <i className="pi pi-search" />
              <InputText
                type="number"
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Numero de prueba"
              />
            </span>
            <span className="p-input-icon-left" style={{width: '220px'}}>
              <i className="pi pi-list" />
              <InputText
                type="number"
                onChange={(e) => setCantPuestos(e.target.value)}
                placeholder="Cantidad Puestos"
              />
            </span>
            <span className="p-input-icon-left" style={{width: '220px'}}>
              <i className="pi pi-user" />
              <InputText
                type="number"
                onChange={(e) => setIdentidad(e.target.value)}
                placeholder="Ti/Cc Deportista"
              />
            </span>
            <span className="p-input-icon-left" style={{width: '220px'}}>
              <i className="pi pi-users" />
              <Dropdown value={nombreClub} onChange={(e) => setNombreClub(e.value)} options={nombresClubs} optionLabel="name" 
                placeholder="Elige el club" />
            </span>
          </div>
        ):(
          <></>
        )}
        <div className="d-flex flex-wrap justify-content-center text-center">
          {resultados.length !== 0 ?(
            resultados.filter((resu)=>((resu.id + 1) + '').includes(globalFilter)).map((result, indexResult)=>{
              return <form key={indexResult} className='formulario-tablas table-responsive-sm'>
                <motion.table style={{margin: '4px', width: '530px', borderRadius: '20px 0', background: 'rgb(50, 51, 51)'}}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration: 1}}
                className='shadow tabla-tiempos'
                >
                  <thead className='text-light'>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <td style={{fontSize: '16px', fontFamily: 'Fira Sans Extra Condensed'}} colSpan={7}>
                        {resultados.length - 1 === indexResult ?(
                          <span>
                            {result.nadadores.length === 1 ? 'No Acumula Para El Trofeo - ' : ''} En Curso...
                          </span>
                        ):(
                          <span>
                            {result.nadadores.length === 1 ? 'No Acumula Para El Trofeo - ' : ''} Prueba Terminada ✔
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th className='id fs-4'>{result.id + 1}</th>
                      <th style={{fontSize: '18px', borderLeft: '1px solid gray'}} colSpan={6}>{result.categoria}</th>
                    </tr>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th style={{fontSize: '18px'}} colSpan={7}>{result.prueba.prueba}</th>
                    </tr>
                    <tr>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Pto</th>
                      <th style={{fontSize: '15px'}}></th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Deportista</th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>T. Insc.</th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Tiempo</th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Rendim.</th>
                      <th style={{fontSize: '15px'}}>Equipo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result.nadadores.slice(0, cantPuestos === '' ? result.nadadores.length : cantPuestos).map((nadador, indexNadador)=>{
                      return <motion.tr key={indexNadador}
                      initial={{ x: -1000, opacity: 0}}
                      animate={{ x: animateList ? -1000 : 0.1, opacity: animateList ? 0 : 1}}
                      transition={{ duration: 0.5, delay: `0.${indexNadador}`, type: 'spring'}}
                      style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexNadador}`}}
                      >
                        {indexNadador === 0 ? <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}><img style={{width: '35px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-medalla-1.png")} alt="medalla primer lugar" /></td> : indexNadador === 1 ? <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}><img style={{width: '35px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-medalla-2.png")} alt="medalla segundo lugar" /></td> :  indexNadador === 2 ? <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}><img style={{width: '35px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-medalla-3.png")} alt="medalla tercer lugar" /></td> : <td className={`pt-2 pb-2 ${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}>{indexNadador + 1}</td>}
                        {nadador.equipo === null ?(
                          <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>
                            <Button type='button' severity='success' icon='pi pi-user-plus' className='btn' style={{borderRadius: '50%', width: '2rem', height: '2rem', padding: 0}} onClick={() => sacarHistorialDeportista(nadador)}/>
                          </td>
                        ):(
                          <td style={{background: 'white'}}></td>
                        )}
                        {nadador.equipo !== null ?(
                          <td className={`text-start ${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>
                            {nadador.equipo.map((eq, indexEq)=>{
                              return <span key={indexEq}>{eq} <hr className='m-0'/></span>
                            })}
                          </td>
                          ):(
                            <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{nadador.nombre}</td>
                        )}
                        <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{nadador.record && nadador.record !== '0:0:0' ? arreglarTiempoEstandar(nadador.record) : nadador.tiempo === '0:0:0' ? 'NSP' : nadador.tiempo === '61:0:0' ? 'DSC' : `${arreglarTiempoEstandar(nadador.tiempo)}`}</td>
                        <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{nadador.tiempo === '0:0:0' ? 'NSP' : nadador.tiempo === '61:0:0' ? 'DSC' : `${arreglarTiempoEstandar(nadador.tiempo)}`}</td>
                        <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{manejoRendimiento(nadador.record, nadador.tiempo) >= 0 && nadador.tiempo !== '61:0:0' && nadador.tiempo !== '0:0:0'? 
                          <label className='d-flex align-items-center justify-content-center'><FcApproval />{arreglarFormatoTiempo(manejoRendimiento(nadador.record, nadador.tiempo))}</label>
                          : 
                          <label className='d-flex align-items-center justify-content-center'><FcHighPriority />{arreglarFormatoTiempo(manejoRendimiento(nadador.record, nadador.tiempo === '61:0:0' ? '0:0:0' : nadador.tiempo))}</label>
                        }</td>
                        <td className={`${nadador.identificacion === identidad || nadador.nombreclub === nombreClub.name ? 'bg-info text-light' : ''}`} style={{fontSize: '10px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{nadador.nombreclub}</td>
                      </motion.tr>
                    })}
                  </tbody>
                </motion.table>
              </form> 
            })
          ):(
            <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{duration: 1}}
            >
              <div className='p-5 text-center'>
                <img className='m-auto' style={{width: '230px'}} src={require('../img/img_logo_swimmingT.png')} alt="logo prowebsports" />
                <h1>¡Bienvenido!</h1>
                <p>No tenemos registros de tiempos aún.</p>
              </div>
            </motion.div>
          )}
        </div>
        <motion.div className="text-center"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{duration: 1}}
        >
          <h5>¿Qué opinas de nuestra plataforma?</h5>
          <div className="d-flex justify-content-around mt-2 m-auto" style={{width: '200px'}}>
            <div className="position-relative">
              <img className='img-reaccion' src={require('../img/gif-meencanta.gif')} alt="emoji" onClick={() => onClicReaccion('meencanta')} style={{cursor:'pointer', width: '50px'}}/>
              <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'meencanta' ? 'bg-danger' : 'bg-success'}`} style={{top: '6px'}}>
                {reacciones ? formatNumeroReacciones(parseInt(reacciones.meencanta)) : '0'}
              </span>
            </div>
            <div className="position-relative">
              <img className='img-reaccion' src={require('../img/gif-like.gif')} alt="emoji" onClick={() => onClicReaccion('like')} style={{cursor:'pointer', width: '50px'}}/>
              <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'like' ? 'bg-danger' : 'bg-success'}`} style={{top: '6px'}}>
                {reacciones ? formatNumeroReacciones( parseInt(reacciones.like)) : '0'}
              </span>
            </div>
            <div className="position-relative" style={{top: '12px'}}>
              <img className='img-reaccion' src={require('../img/gif-dislike.gif')} alt="emoji" onClick={() => onClicReaccion('dislike')} style={{cursor:'pointer', width: '50px'}}/>
              <span className={`position-absolute start-100 translate-middle badge rounded-pill ${getCookieReaccion('reaxx') === 'dislike' ? 'bg-danger' : 'bg-success'}`} style={{top: '-4px'}}>
                {reacciones ? formatNumeroReacciones(parseInt(reacciones.dislike)) : '0'}
              </span>
            </div>
          </div>
        </motion.div>
      </Dialog>
      <Dialog visible={dialogHistorial} style={{ width: '50em' }} breakpoints={{ '700px': '100%' }} header="Historial Deportivo" modal className="p-fluid" onHide={() => setDialogHistorial(false)}>
        <div className="field">
          {historialDeportista ?(
            <div>
              <h1 className='text-center mb-0' style={{fontFamily: 'Poppins', background: 'rgb(212,212,212)'}}>
                {historialDeportista?.equipo === null ? (
                  historialDeportista?.nombre
                ):(
                  historialDeportista?.equipo.map((eq, indexEq)=>{
                    return (historialDeportista?.equipo.length - 1) !== indexEq ? eq + ' - ' : eq;
                  })
                )}
              </h1>
              <h5 className='text-center bg-light' style={{fontFamily: 'Poppins'}}>
                {historialDeportista?.nombreclub}
              </h5>
              <div className='mt-4 mb-4 p-3 d-flex flex-wrap justify-content-around align-items-center' style={{fontFamily: 'Poppins', background: 'rgb(212,212,212)'}}>
                <p className='m-0'><b>Edad:</b> {historialDeportista?.edad}</p>
              </div>
              {historialDeportista?.pruebas.map((prue, indexPrue)=>{
                return <table key={indexPrue} className='w-100 table table-bordered table-striped text-center'>
                <thead>
                  <tr style={{fontFamily: 'Poppins', background: 'rgb(212,212,212)'}}>
                    <th className='fs-6'>{prue.nPrueba}</th>
                    <th className='fs-6' colSpan={4}>{prue.prueba}</th>
                  </tr>
                  <tr>
                    <th>Posición</th>
                    <th>T. Insc.</th>
                    <th>Tiempo</th>
                    <th>Rendim.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  {prue.puesto === 1 ? <td style={{fontFamily: 'Poppins'}}><img style={{width: '25px'}} src={require("../img/icon-medalla-1.png")} alt="medalla primer lugar" /></td> : prue.puesto === 2 ? <td style={{fontFamily: 'Poppins'}}><img style={{width: '25px'}} src={require("../img/icon-medalla-2.png")} alt="medalla segundo lugar" /></td> :  prue.puesto === 3 ? <td style={{fontFamily: 'Poppins'}}><img style={{width: '25px'}} src={require("../img/icon-medalla-3.png")} alt="medalla tercer lugar" /></td> : <td className={`pt-2 pb-2`} style={{fontFamily: 'Poppins'}}>{prue.puesto}</td>}
                    <td>{arreglarTiempoEstandar(prue.record)}</td>
                    <td>{prue.tiempo !== '61:0:0' ? prue.tiempo !== '0:0:0' ? arreglarTiempoEstandar(prue.tiempo) : 'NSP' : 'DSC' }</td>
                    <td>{manejoRendimiento(prue.record, prue.tiempo) >= 0 && prue.tiempo !== '61:0:0' && prue.tiempo !== '0:0:0'? 
                      <label className='d-flex align-items-center justify-content-center'><FcApproval />{arreglarFormatoTiempo(manejoRendimiento(prue.record, prue.tiempo))}</label>
                      : 
                      <label className='d-flex align-items-center justify-content-center'><FcHighPriority />{arreglarFormatoTiempo(manejoRendimiento(prue.record, prue.tiempo === '61:0:0' ? '0:0:0' : prue.tiempo))}</label>
                    }</td>
                  </tr>
                </tbody>
              </table>
              })}
            </div>
          ):(
            <></>
          )}
        </div>
      </Dialog>
      <Dialog visible={dialogCotizacion} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header='Cotización' modal className="p-fluid" onHide={() => setDialogCotizacion(false)}>
        <div className="field">
          <div className='mb-2 pdf-min pdf-max' style={{width: '100%'}}>
            <embed src={require('../archivos/cotizacion.pdf')} width='100%' height='100%' type='application/pdf'/>
          </div>
        </div>
      </Dialog>
      <Conctatenos/>
      <Dialog visible={dialogChampions} style={{ width: '50em', height: '2397px' }} breakpoints={{ '700px': '100%' }} header="Encuesta PROWEBSPORTS CHAMPIONS" modal className="p-fluid" onHide={() => setDialogChampions(false)}>
        <div className="field">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe5SsAEAHqX1_MeGT1cPRyXGzNJGO7fDIW4e9LfWeCzjQgw-Q/viewform?embedded=true" width="100%" height="2550" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
        </div>
      </Dialog>
      <Dialog visible={dialogNuevaContrasena} style={{ width: '30em' }} breakpoints={{ '700px': '100%' }} header="Restablecer Contraseña" modal className="p-fluid" onHide={() => setDialogNuevaContrasena(false)}>
        <div className="field">
          <form onSubmit={onSubmitRestablecerContrasena}>
            <div className="d-flex justify-content-center">
              <img
                className="w-50 m-auto"
                src={require("../img/img-logo-swimmingt.webp")} 
                alt="logo de prowebsports"
              />
            </div>
            <div className="p-inputgroup flex-1 mb-1">
              <span className="p-inputgroup-addon">
                  <i className={nuevaContrasena.length >= 6 ? 'pi pi-unlock' : 'pi pi-lock'}></i>
              </span>
              <Password value={nuevaContrasena} onChange={(e) => setNuevaContrasena(e.target.value)} placeholder="Contraseña" toggleMask minLength={6} required
              className={`${nuevaContrasena !== '' && nuevaContrasena.length >= 6 ? '' : 'p-invalid'}`}
              feedback={false}/>
            </div>
            <input type="submit" className="btn btn-success w-100" value='Restablecer' />
          </form>
        </div>
      </Dialog>
      <Dialog visible={dialogCalendario} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header='Calendario' modal className="p-fluid" onHide={() => setDialogCalendario(false)}>
        <div className="field">
          <Calendario cliente={cliente}/>
        </div>
      </Dialog>
    </>
  );
}

export default Torneos;